import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import CookieConsent from "react-cookie-consent"

import Header from "./header"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div>
        <main>{children}</main>
        <div
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          <footer>
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <Link to="/impressum">Impressum</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/datenschutz">Datenschutz</Link>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
